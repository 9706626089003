import { Box } from 'components/Box';
import { Layout } from 'components/Layout';
import React from 'react';
import { Heading } from 'components/Heading';
import { Button } from 'components/Button';

const NotFound = () => (
    <Layout>
        <Box
            height="80vh"
            // flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <Heading kind="sectionHeading">404: Not found</Heading>
            <Button href="/">Go back</Button>
        </Box>
    </Layout>
);

export default NotFound;
